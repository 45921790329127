import React, { useRef, useState } from "react";
import left from '../../images/caret-left-solid.svg';
import right from '../../images/caret-right-solid.svg';
import stars from '../../images/Star.svg';
import oma from '../../images/oma.png';
import quote from '../../images/“.svg';
import crown from '../../images/crown.svg';
import Carousel from "@itseasy21/react-elastic-carousel";


const Test = () =>{
    // const [activeElement, setActiveElement] = useState(0);
    // const totalElements = 4; // Set the total number of elements in your slider

    // const handlePrevClick = () => {
    //     setActiveElement((prevElement) => (prevElement > 0 ? prevElement - 1 : totalElements - 1));
    // };

    // const handleNextClick = () => {
    //     setActiveElement((prevElement) => (prevElement < totalElements - 1 ? prevElement + 1 : 0));
    // };

    const carouselRef = useRef(null); // Create a ref for the carousel
    const [activeElement, setActiveElement] = useState(0);
    const totalElements = 4*2; // Set the total number of elements in your slider

    const items = [
        {
            id: 1,
            content:
                "Our calls both inbound and outbound have been seamless, whenever issues arises, it is fixed immediately. More applicants can reach us, it's really a different experience. More-so, the application is user-friendly.",
            author: "Lead City",
            role: "CEO, OmaLeague",
        },

        {
            id: 2,
            content:
                "Response time and support is pretty swift unlike some Company",
            author: "1timeshop",
            role: "CEO, OmaLeague",
        },

        {
            id: 3,
            content:
                "Active with their response time and highly impressive with the services rendered.",
            author: "Sportybet",
            role: "CEO, OmaLeague",
        },

        // {
        //     id: 4,
        //     content:
        //         "Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features",
        //     author: "Chioma Okere",
        //     role: "CEO, OmaLeague",
        // },

        // {
        //     id: 5,
        //     content:
        //         "Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features",
        //     author: "Chioma Okere",
        //     role: "CEO, OmaLeague",
        // },

        // {
        //     id: 6,
        //     content:
        //         "Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features",
        //     author: "Chioma Okere",
        //     role: "CEO, OmaLeague",
        // },

        // {
        //     id: 7,
        //     content:
        //         "Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features",
        //     author: "Chioma Okere",
        //     role: "CEO, OmaLeague",
        // },

        // {
        //     id: 8,
        //     content:
        //         "Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features",
        //     author: "Chioma Okere",
        //     role: "CEO, OmaLeague",
        // },
        // Add more items as needed
    ];

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 },
    ];

    // const handlePrevClick = () => {
    //     carouselRef.current.goTo(carouselRef.current.state.currentItem - 1);
    //   };
    
    //   const handleNextClick = () => {
    //     carouselRef.current.goTo(carouselRef.current.state.currentItem + 1);
    //   };
    const handlePrevClick = () => {
        setActiveElement((prevElement) => (prevElement > 0 ? prevElement - 1 : items.length - 1));
        carouselRef.current.slidePrev();
      };
      
      const handleNextClick = () => {
        setActiveElement((prevElement) => (prevElement < items.length - 1 ? prevElement + 1 : 0));
        carouselRef.current.slideNext();
      };
      
      
    // const handlePrevClick = () => {
    //     setActiveElement((prevElement) => (prevElement > 0 ? prevElement - 1 : totalElements - 1));
    //     carouselRef.current.goTo(carouselRef.current.state.currentItem - 1);
    //   };
    
    //   const handleNextClick = () => {
    //     setActiveElement((prevElement) => (prevElement < totalElements - 1 ? prevElement + 1 : 0));
    //     carouselRef.current.goTo(carouselRef.current.state.currentItem + 1);
    //   };

    return(
        <section className="test">
            <div className="top">
                <h4>TESTIMONIALS</h4>
                <div className="tBot">
                    <h2>See what our clients have to say</h2>
                    <div className="but">
                        <button onClick={handlePrevClick}>
                            <img src={left} alt="previous"/>
                        </button>

                        <button onClick={handleNextClick}>
                            <img src={right} alt="next" />
                        </button>
                    </div>
                </div>
            </div>
            <Carousel ref={carouselRef}  showArrows={false} itemsToShow={3} pagination={false} enableAutoPlay={false} className="mid">
            {items.map((item) => (
                <div key={item.id} className={`testimonial ${activeElement === item.id - 1 ? 'active' : ''}`}>
                    <img src={stars} alt=""></img>
                    <p>{item.content}</p>
                    <span>
                    <div className="hold">
                        <div className="img">
                        {/* <img src={oma} alt="" /> */}
                        </div>
                        <div className="text">
                        <h5>{item.author}</h5>
                        <p>{item.position}</p>
                        </div>
                    </div>
                    <img src={quote} alt="" />
                    </span>
                </div>
            ))}
            </Carousel>
            <div className="line"></div>
            <div className="bot">
                <ul>
                    <li>
                        <img src={crown} alt=""></img>
                        <div>
                            <h5>560+</h5>
                            <p>Projects Completed</p>
                        </div>
                    </li>
                    <li>
                        <img src={crown} alt="" />
                        <div>
                            <h5>236+</h5>
                            <p>Satisfied Clients</p>
                        </div>
                    </li>
                    <li>
                        <img src={crown} alt="" />
                        <div>
                            <h5>1432+</h5>
                            <p>Combined Years of Experience</p>
                        </div>
                    </li>
                    <li>
                        <img src={crown} alt="" />
                        <div>
                            <h5>7+</h5>
                            <p>Awards of Excellence</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Test;


{/* <div className={`testimonial ${activeElement === 0 ? 'active' : ''}`}>
                    <img src={stars} alt=""></img>
                    <p>
                        Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features
                    </p>
                    <span>
                        <div className="hold">
                            <div className="img">
                                <img src={oma} alt="" />
                            </div>
                            <div className="text">
                                <h5>Chioma Okere</h5>
                                <p>CEO, OmaLeague</p>
                            </div>
                        </div>
                        <img src={quote} alt="" />
                    </span>
                </div>
                <div className={`testimonial ${activeElement === 1 ? 'active' : ''}`}>
                    <img src={stars} alt=""></img>
                    <p>
                        Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features
                    </p>
                    <span>
                        <div className="hold">
                            <div className="img">
                                <img src={oma} alt="" />
                            </div>
                            <div className="text">
                                <h5>Chioma Okere</h5>
                                <p>CEO, OmaLeague</p>
                            </div>
                        </div>
                        <img src={quote} alt="" />
                    </span>
                </div>
                <div className={`testimonial ${activeElement === 2 ? 'active' : ''}`}>
                    <img src={stars} alt=""></img>
                    <p>
                        Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features
                    </p>
                    <span>
                        <div className="hold">
                            <div className="img">
                                <img src={oma} alt="" />
                            </div>
                            <div className="text">
                                <h5>Chioma Okere</h5>
                                <p>CEO, OmaLeague</p>
                            </div>
                        </div>
                        <img src={quote} alt="" />
                    </span>
                </div>
                <div className={`testimonial ${activeElement === 3 ? 'active' : ''}`}>
                    <img src={stars} alt=""></img>
                    <p>
                        Tech4mation delivers constant software updates periodically, to keep the system up to date with the latest technologies and security features
                    </p>
                    <span>
                        <div className="hold">
                            <div className="img">
                                <img src={oma} alt="" />
                            </div>
                            <div className="text">
                                <h5>Chioma Okere</h5>
                                <p>CEO, OmaLeague</p>
                            </div>
                        </div>
                        <img src={quote} alt="" />
                    </span>
                </div> */}